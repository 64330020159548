import React from "react"
import { graphql } from "gatsby"
import BlogLoop from "../components/blog/blog-post-archive-Loop"
import BlogFeaturedSlider from "../components/blog/BlogFeaturedSlider"
import Filters from "../components/blog/Filters"
import styled from "styled-components"
import Seo from "../components/seo"

const BlogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media only screen and (min-width: 1024px) {
    display: block;
  }

  .blog-featured-slider {
    order: 3;
  }

  .blog-filters {
    display: none;
  }
`

const BlogIndex = ({
  data,
  pageContext: {
    nextPagePath,
    previousPagePath,
    postCat,
    totalPages,
    currentPage,
  },
}) => {
  console.log(data)
  const posts = data.posts.nodes

  // const locations = data.locations.nodes
  // const types = data.types.nodes
  // const slider =
  //   data.slider.acf_featured_articles_slider_shopping_lifestyle
  //     .featuredPostSliderShoppingLifestyle
  // const seo = data.seo.seo
  return (
    <BlogWrapper>
      {/* <Seo
        title={seo.title}
        description={seo.metaDesc}
        image={data.og.options.acf_options.ogShoppingLifestyle.mediaItemUrl}
      /> */}
      {/* <BlogFeaturedSlider
        slider={slider}
        postCat={postCat}
        className="blog-featured-slider"
      />
      <Filters types={types} locations={locations} className="blog-filters" /> */}

      <BlogLoop
        posts={posts}
        postCat={postCat}
        nextPagePath={nextPagePath}
        previousPagePath={previousPagePath}
        totalPages={totalPages}
        currentPage={currentPage}
        newsCats={data.newsCats.wpChildren.nodes.map(cat => cat.slug)}
        postCatOverride={true}
        className="blog-loop"
      />
    </BlogWrapper>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query NewsPostArchive($offset: Int!, $postsPerPage: Int!, $postCat: String!) {
    og: wp {
      options {
        acf_options {
          ogShoppingLifestyle {
            mediaItemUrl
          }
        }
      }
    }

    seo: wpPage(title: { eq: "News" }) {
      seo {
        metaDesc
        title
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            id
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        twitterTitle
        twitterDescription
      }
    }

    #================================
    # News Cats
    #================================

    newsCats: wpCategory(name: { eq: "News" }) {
      wpChildren {
        nodes {
          slug
        }
      }
    }

    posts: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $postCat } } } }
      }
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        link
        slug
        excerpt
        categories {
          nodes {
            name
            slug
            wpChildren {
              nodes {
                name
                slug
              }
            }
          }
        }
        acf_customFeaturedImage {
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    types: allWpStoryTagType(filter: { count: { gt: 0 } }) {
      nodes {
        slug
        name
      }
    }

    locations: allWpStoryTagLocation(filter: { count: { gt: 0 } }) {
      nodes {
        name
        slug
      }
    }

    # slider: wpPage(title: { eq: $pageTitle}) {
    #   id
    #   title
    #   acf_featured_articles_slider_shopping_lifestyle {
    #     featuredPostSliderShoppingLifestyle {
    #       post {
    #         ... on WpPost {
    #           id
    #           title
    #           excerpt
    #           link
    #           acf_customFeaturedImage {
    #             featuredImage {
    #               localFile {
    #                 childImageSharp {
    #                   gatsbyImageData
    #                 }
    #               }
    #             }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
  }
`
